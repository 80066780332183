import React from 'react';

const Contact = () => (
    <div style={{ padding: '1rem' }}>
        <h2>Contacto</h2>
        <p>Dirección: Rocas s/n esq. Erizo y Dragonero, Cabo San Lucas, México</p>
        <p>Teléfono: 6241436709</p>
        <p>Email: primarturogro@hotmail.com</p>
        <p>Horario: 8:00 a.m. a 12:30 p.m.</p>
    </div>
);

export default Contact;
