import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Slider from './components/Slider';
import NewsSection from './components/NewsSection';
import Sections from './components/Sections';
import Teachers from './components/Teachers';
import Contact from './components/Contact';
import DownloadPage from './components/DownloadPage';  // Asegúrate de importar DownloadPage
import './App.css';

const App = () => (
  <div style={{ backgroundColor: '#f0f0f0', color: '#333' }}>
    <Navbar />
    <Routes>
      <Route path="/" element={<><Slider /><NewsSection /></>} />
      <Route path="/sections" element={<Sections />} />
      <Route path="/teachers" element={<Teachers />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/sicrer" element={<DownloadPage />} />  {/* Asegúrate de que esta ruta esté aquí */}
    </Routes>
    <footer style={{ textAlign: 'center', padding: '1rem', backgroundColor: '#fff', borderTop: '1px solid #ddd' }}>
      <p style={{ fontSize: '0.9rem', marginBottom: '0.5rem' }}>
        <a href="https://primarturogro.edu.mx">Página diseñada por</a> el
        <a href="https://rmontor.com"> Profr. Rodrigo Montor Velis</a>
      </p>
      <p style={{ fontSize: '0.9rem', marginBottom: '0.5rem' }}>
        Licenciado bajo
        <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style={{ display: 'inline-block' }}>
          CC BY-NC-ND 4.0
        </a>
      </p>
      <p style={{ fontSize: '0.8rem', margin: 0 }}>
        <a href="https://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1" target="_blank" rel="license noopener noreferrer" style={{ display: 'inline-block', marginRight: '5px' }}>
          <img style={{ height: '22px', verticalAlign: 'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1" alt="CC" />
          <img style={{ height: '22px', verticalAlign: 'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1" alt="BY" />
          <img style={{ height: '22px', verticalAlign: 'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1" alt="NC" />
          <img style={{ height: '22px', verticalAlign: 'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/nd.svg?ref=chooser-v1" alt="ND" />
        </a>
      </p>
    </footer>
  </div>
);

export default App;
