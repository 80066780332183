import React, { useState } from 'react';
import axios from 'axios';
import './Teachers.css';

const Teachers = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('/api/login', { username, password });

            if (response.data.success) {
                if (response.data.level === 99) {
                    // Redirigir o mostrar el menú para editar noticias
                    window.location.href = '/news-management'; // Ajusta la ruta según tu necesidad
                } else {
                    setError('No tienes permisos para acceder a esta sección.');
                }
            } else {
                setError('Credenciales incorrectas');
            }
        } catch (error) {
            setError('Error durante el inicio de sesión');
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={handleLogin}>
                <div className="form-group">
                    <label htmlFor="username">Usuario:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        autoComplete="username"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="password">Contraseña:</label>
                    <input
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        autoComplete="current-password"
                    />
                </div>
                <button type="submit">Iniciar sesión</button>
                {error && <p className="error">{error}</p>}
            </form>
        </div>
    );
};

export default Teachers;
