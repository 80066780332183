import React from 'react';

const Sections = () => (
    <div style={{ padding: '1rem' }}>
        <p>Página en construcción</p>
        <h2>Nuevo Ingreso</h2>
        <p>Estudiantes de 1er año. Próximamente.</p>

        <h2>Preinscripciones</h2>
        <p>En febrero. Info próximamente.</p>

        <h2>Reinscripciones</h2>
        <p>De 2do a 6to grado. En agosto. Próximamente.</p>

        <h2>Solicitud de documentos</h2>
        <p>Plataforma en construcción.</p>
    </div>
);

export default Sections;
