import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png'; // Asegúrate de que la ruta sea correcta
import sepIcon from '../images/sep.png'; // Asegúrate de que la ruta sea correcta

const Navbar = () => (
    <nav style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#003366', padding: '0.5rem 1rem' }}>
        <Link to="/">
            <img src={logo} alt="Esc. Prim. Arturo Guerrero González T.M." style={{ height: '100px', borderRadius: '5px' }} />
        </Link>
        <div style={{ color: '#fff', fontSize: '1.2rem' }}>
            <Link to="/" style={{ color: '#fff', textDecoration: 'none', margin: '0 1rem' }}>Inicio</Link>
            <Link to="/sections" style={{ color: '#fff', textDecoration: 'none', margin: '0 1rem' }}>Secciones</Link>
            <Link to="/teachers" style={{ color: '#fff', textDecoration: 'none', margin: '0 1rem' }}>Docentes</Link>
            <Link to="/contact" style={{ color: '#fff', textDecoration: 'none', margin: '0 1rem' }}>Contacto</Link>
        </div>
        <img src={sepIcon} alt="SEP" style={{ height: '100px', borderRadius: '5px' }} />
    </nav>
);

export default Navbar;
