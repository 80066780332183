import React from 'react';

const DownloadPage = () => (
    <div style={{ textAlign: 'center', padding: '20px' }}>
        <a
            href="https://drive.google.com/file/d/1y8fbOy2YGJQfhjozdgdHFcl6OYaxo1P-/view?usp=drive_link"
            target="_blank"
            rel="noopener noreferrer"
            style={{
                display: 'inline-block',
                padding: '10px 20px',
                fontSize: '18px',
                color: '#fff',
                backgroundColor: '#007bff',
                textDecoration: 'none',
                borderRadius: '5px',
                margin: '10px auto',
            }}
        >
            Descargar SiCRED aquí
        </a>
    </div>
);

export default DownloadPage;
