import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import slider1 from '../images/slider1.jpg';
import slider2 from '../images/slider2.jpg';
import slider3 from '../images/slider3.jpg';

const Slider = () => (
    <div className="slider-container">
        <Carousel className="slider">
            <Carousel.Item>
                <img src={slider1} alt="Slide 1" />
            </Carousel.Item>
            <Carousel.Item>
                <img src={slider2} alt="Slide 2" />
            </Carousel.Item>
            <Carousel.Item>
                <img src={slider3} alt="Slide 3" />
            </Carousel.Item>
        </Carousel>
    </div>
);

export default Slider;
